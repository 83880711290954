*, ::after, ::before {
  box-sizing: border-box;
}
body, html {
  height: 100%;
  font-family: 'Lato', sans-serif;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}
.bg-box {
  background-position: center center;
  background-repeat:no-repeat;
  background-size: cover;
  position: relative;

}
.bg-box::before {
  content:" ";
  position:absolute;
  background-color: rgba(239,246,255,.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/*
.white-mask {
  position:absolute;
  background-color: rgba(239,246,255,.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
*/
.bg-box-full {
  height: 100%;
}
.bg-box-full .content-box{
  position:absolute;
}
.bg-box-auto {
  min-height: 100%;
  padding-top: 10%;
}
.bg-box-auto .content-box{
  position:relative;
  padding: 20px 20px 60px 20px;
}
.content-box{
  top: 20%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  text-align: center;
}
.content-text {
  font-size:24px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 30px 50px 30px;
  max-width: 70%;
  text-align: center;
  font-weight: bold;
}
.blue-text{
  color:#1b74e8;
}
.main-title{
  font-size: 50px;
  font-weight: bold;
  color: black;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.home {
  height: 100%;
}
#root {
  height: 100%;
}
.button {
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  cursor: pointer;
}
.button a{
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 10px;
}
.white-button {
   background-color: white;
   color: black;
}
.white-button a{
  color: black;
}
.blue-button {
  background-color: #1b74e8;
  color: white;
}
.blue-button a{
  color: white;
}
.contact-us-button {
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #1b74e8;
  color:#1b74e8;
  position: absolute;
  bottom:20px;
  right: 20px;
}
.contact-us-button a{
  text-decoration: none;
  color:#1b74e8;
  display: block;
  width: 80px;
  padding: 5px;
}
.step-box {
  width: 340px;
  background-color: #eff6ff;
  border: 1px solid #1b74e8;
  display: inline-block;
  margin:50px 20px 50px 20px;
  border-radius: 15px;
  padding: 20px;
  height: 160px;
  vertical-align: top;
  position: relative;
}
.step-num-holder{
  position: absolute;
  top:-16px;
  left: 0px;
  right: 0px;
  text-align: center;
}
.step-num {
  display: inline-block;
  background-color: #eff6ff;
  border: 1px solid #1b74e8;
  border-radius: 17px;
  padding: 8px;
  width: 36px;
  font-weight: bold;
  color: #1b74e8;
}
.step-title {
  font-weight: bold;
  font-size:18px;
  margin: 10px;
}
.step-content{
  font-size:14px;
}
.video-content iframe {
  width:450px;
  height: 250px;
  border: none;
  margin:30px;
}

@media (max-width: 800px) {
  .main-title{
    font-size: 30px;
  }
  .content-text {
    font-size: 20px;
  }

  .video-content iframe {
    width:90%;
    height: 250px;
  }
  .step-box {
    width:80%;
    margin-top:10px;
    margin-bottom:20px;
  }

}
